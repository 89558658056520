const PROVINCE_PATTERN = '(?:[1-9]|1[0-3])';
const SPECIAL_PREFIX_PATTERN = '(?:AV|PI)';
const GENERAL_PREFIX_PATTERN = '(?:E|N|PE)';
const BOOK_MIN_LENGTH = 2;
const BOOK_MAX_LENGTH = 5;
const RECORD_MIN_LENGTH = 2;
const RECORD_MAX_LENGTH = 5;
const TOTAL_PARTS = 3;

function format(dni: string): string {
  const normalized = dni
    .trim()
    .toUpperCase()
    .replace(/[\u2010\u2011]/g, '-')
    .replace(/[^0-9A-Z-]/g, '')
    .replace(/-{2,}/g, '-')
    .replace(/^-/, '');

  const parts = normalized.split('-');
  if (parts.length > TOTAL_PARTS) {
    return parts.slice(0, TOTAL_PARTS).join('-');
  }

  return parts.join('-');
}
function validate(dni: string): boolean {
  const provincePattern = `${PROVINCE_PATTERN}`;
  const specialProvincePattern = `${PROVINCE_PATTERN}${SPECIAL_PREFIX_PATTERN}`;
  const generalPrefixPattern = `${GENERAL_PREFIX_PATTERN}`;
  const bookPattern = `\\d{${BOOK_MIN_LENGTH},${BOOK_MAX_LENGTH}}`;
  const recordPattern = `\\d{${RECORD_MIN_LENGTH},${RECORD_MAX_LENGTH}}`;

  const regularDniRegex = new RegExp(`^${provincePattern}-${bookPattern}-${recordPattern}$`);
  const specialDniRegex = new RegExp(`^${specialProvincePattern}-${bookPattern}-${recordPattern}$`);
  const generalDniRegex = new RegExp(`^${generalPrefixPattern}-${bookPattern}-${recordPattern}$`);

  const formattedDni = format(dni);

  return (
    regularDniRegex.test(formattedDni) ||
    specialDniRegex.test(formattedDni) ||
    generalDniRegex.test(formattedDni)
  );
}

export const paDniUtils = { validate, format };
