import type { CountryCode } from '@/types/country-code';

import { brDniUtils } from './br/dni-utils';
import { clDniUtils } from './cl/dni-utils';
import { esDniUtils } from './es/dni-utils';
import { paDniUtils } from './pa/dni-utils';
import { peDniUtils } from './pe/dni-utils';

type DniUtilsType = {
  format: (dni: string) => string;
  validate: (dni: string) => boolean;
}

type DniUtilsMap = {
  [key in CountryCode]: DniUtilsType | undefined;
}

export const dniUtils: DniUtilsMap = {
  PA: paDniUtils,
  PE: peDniUtils,
  CL: clDniUtils,
  BR: brDniUtils,
  ES: esDniUtils,
};
