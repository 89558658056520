<script setup lang="ts">
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { object, string } from 'yup';

import type { UserForm } from '@/api/users';
import type { UserSessionMode } from '@/types/user-session-mode';

import BaseMokButton from './base-mok-button.vue';
import UserSessionHeader from './user-session-header.vue';

const { t } = useI18n();

type Props = {
  refererUrl: string;
};

const emit = defineEmits<{(event: 'toggle-mode', value?: UserSessionMode): void;
  (event: 'submit', values: UserForm): void;
}>();

const validationSchema = object().shape({
  email: string().required().email(),
});

const { values, handleSubmit, meta } = useForm<UserForm>(
  {
    validationSchema,
    validateOnMount: true,
  },
);

const submitForm = handleSubmit(() => {
  emit('submit', values);
});

defineProps<Props>();
</script>

<template>
  <user-session-header
    :back-href="refererUrl"
    :title="t('userSession.resetPasswordPage.title')"
    :subtitle="t('userSession.resetPasswordPage.subtitle')"
  />
  <form
    class="flex flex-col gap-y-4"
  >
    <base-mok-input
      class="mb-4"
      :label="t('userSession.email')"
      :placeholder="t('userSession.emailPlaceholder')"
      name="email"
      autocomplete="email"
      data-testid="email-input"
    />
    <base-mok-button
      variant="primary"
      :label="t('userSession.resetPassword')"
      :disabled="!meta.valid"
      @click="submitForm"
    />
  </form>
</template>
