<script setup lang="ts">
import { useForm } from 'vee-validate';
import { object, string } from 'yup';

import type { UserForm } from '@/api/users';
import type { UserSessionMode } from '@/types/user-session-mode';

import BaseMokButton from './base-mok-button.vue';
import UserSessionHeader from './user-session-header.vue';

type Props = {
  refererUrl: string;
  passwordRecoveryEnabled: boolean;
};

const emit = defineEmits<{(event: 'toggle-mode', value?: UserSessionMode): void;
  (event: 'submit', values: UserForm): void;
}>();

const validationSchema = object().shape({
  email: string().required().email(),
  password: string().required(),
});

const { values, handleSubmit, meta } = useForm<UserForm>(
  {
    validationSchema,
    validateOnMount: true,
  },
);

const submitForm = handleSubmit(() => {
  emit('submit', values);
});

defineProps<Props>();
</script>

<template>
  <user-session-header
    :back-href="refererUrl"
    :title="$t('userSession.loginPage.title')"
    @back="emit('toggle-mode', 'login')"
  />
  <form class="flex flex-col gap-y-4">
    <base-mok-input
      :label="$t('userSession.email')"
      name="email"
      autocomplete="email"
      :placeholder="$t('userSession.emailPlaceholder')"
    />
    <div
      class="flex flex-col gap-2"
    >
      <base-password-input
        :label="$t('userSession.password')"
        name="password"
        autocomplete="current-password"
        :placeholder="$t('userSession.passwordPlaceholder')"
      />
      <base-mok-button
        v-if="passwordRecoveryEnabled"
        class="self-end"
        variant="link"
        :label="$t('userSession.forgotPassword')"
        @click="emit('toggle-mode', 'resetPassword')"
      />
    </div>
    <div
      class="flex flex-col gap-3"
    >
      <base-mok-button
        type="submit"
        variant="primary"
        class="mt-4"
        :label="$t('userSession.login')"
        :disabled="!meta.valid"
        @click="submitForm"
      />
      <i18n-t
        keypath="userSession.noAccount"
        tag="div"
        class="text-right text-sm text-slate-900"
      >
        <template #register>
          <base-mok-button
            class="underline underline-offset-2"
            variant="link"
            :label="$t('userSession.registerHere')"
            @click="emit('toggle-mode', 'register')"
          />
        </template>
      </i18n-t>
    </div>
  </form>
</template>
